import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../Layout/Layout'
import Search from './Search/Search'
import RunIndex from './RunIndex/RunIndex'

const Dashboard = () => {
  return (
    <Layout>
      <Routes>
        <Route path={'/'} element={<Search />} />
        <Route path={'/index'} element={<RunIndex />} />
        <Route path={'*'} element={<Navigate replace to="/dashboard" />} />
      </Routes>
    </Layout>
  )
}

export default Dashboard
