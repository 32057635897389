import { StyledLayout, CoreContent } from './Layout.Styled'
import Header from './Header/Header'
// import Footer from './Footer/Footer'
import Secure from './Secure/Secure'
import { backendDomain } from '../../utility'

const Layout = ({
  children,
  className,
  hideHeader = false,
  authRequired = true,
}: {
  children: React.ReactNode
  className?: string
  hideHeader?: boolean
  authRequired?: boolean
}) => {
  process.env.REACT_APP_backendDomain = backendDomain
  return (
    <StyledLayout className={className}>
      <Secure authRequired={authRequired}>
        {!hideHeader && <Header />}
        <CoreContent id="core-content" hideHeader={hideHeader}>
          {children}
        </CoreContent>
        {/* <Footer /> */}
      </Secure>
    </StyledLayout>
  )
}

export default Layout
