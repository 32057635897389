import styled from 'styled-components'

export const StyledLeftMenu = styled.div`
  position: absolute;
  top: calc(20px + ${({ theme }) => theme.headerHeight});
  left: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: none;
  }
`

export const Item = styled.a`
  color: ${({ theme }) => theme.color.font.primary};
  font-size: ${({ theme }) => theme.font.size.sm};
  cursor: pointer;
  text-decoration: none;
`
