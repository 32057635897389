import styled from 'styled-components'

export const StyledResults = styled.div``

export const CurrentStickyHeader = styled.div`
  position: sticky;
  height: 50px;
  top: ${({ theme }) => theme.headerHeight};
  background-color: ${({ theme }) => theme.color.background.secondary};
`

export const CustomText = styled.div<{ headline?: boolean }>`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ headline }) => (headline ? '1.5rem' : '1rem')};
`
