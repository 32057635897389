import { useRef } from 'react'
import { Project as ProjectType } from '../prepareProjectCollection'
import { StyledProject, CustomText, Header } from './Project.Styled'
import Result from './Result/Result'
import { Sticky } from 'semantic-ui-react'

type Props = {
  project: ProjectType
  className?: string
}

const Project = ({ project, className }: Props) => {
  const ref: any = useRef()
  const sort = () =>
    Array.isArray(project.entries)
      ? project.entries.sort((a, b) => {
          if (a.path < b.path) {
            return -1
          }
          if (a.path > b.path) {
            return 1
          }
          return 0
        })
      : []

  return (
    <StyledProject id={`project-${project.id}`} className={className} data-project-id={project.id} ref={ref}>
      <Sticky active offset={50} context={ref}>
        <Header>
          <CustomText>{project.name}</CustomText>
        </Header>
      </Sticky>
      {sort().map((result) => (
        <Result key={result.path} result={result} />
      ))}
    </StyledProject>
  )
}

export default Project
