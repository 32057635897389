import styled from 'styled-components'

export const StyledProject = styled.div`
  margin-top: 50px;
  position: relative;
`

export const CustomText = styled.div`
  color: ${({ theme }) => theme.color.font.primary};
  font-size: ${({ theme }) => theme.font.size.lg};
`

export const Header = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.background.primary};
`
