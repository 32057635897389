import { props, useFunctional } from '@think-internet/zeus-frontend-package'
import { useCallback, useRef, useState } from 'react'
import SearchResult from '../../../../../lib/functional_routes/search'
import { Container, Button, Input } from '@think-internet/ui-components'
import { SearchForm, SearchWrapper, StyledSearch, SearchContainer } from './Search.Styled'
import routes from '../../../redux/routes'
import Results from './Results/Results'
import { useSelector } from 'react-redux'

type Result = Array<SearchResult> | false | undefined

const Search = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const searchCall = useFunctional(routes.search)
  const [search, setSearch] = useState<string>('')
  const [searchResults, setSearchResults] = useState<Array<SearchResult>>([])
  const ref: any = useRef()

  const searchRun = useCallback(async (): Promise<void> => {
    const result: Result = await searchCall({ search })

    if (Array.isArray(result)) setSearchResults(result)
  }, [searchCall, search])

  return (
    <StyledSearch ref={ref}>
      <SearchWrapper>
        <SearchContainer>
          <SearchForm onSubmit={searchRun}>
            <Input value={search} onChange={setSearch} placeholder={translation.search.input.placeholder} />
            <Button type="submit" text="search" />
          </SearchForm>
        </SearchContainer>
      </SearchWrapper>
      <Container>
        <Results searchResults={searchResults} />
      </Container>
    </StyledSearch>
  )
}

export default Search
