import SearchResult from '../../../../../../lib/functional_routes/search'

export type Project = {
  id: number
  name: string
  entries: Array<SearchResult>
}

type SearchResults = Array<SearchResult>

const getEntriesByProjectName = (projectName: string, searchResults: SearchResults): SearchResults => {
  return searchResults.filter((result) => result.projectName === projectName)
}

const prepareProjectCollection = (searchResults: SearchResults): Array<Project> => {
  return searchResults.reduce((acc: Project[], result: SearchResult, i: number) => {
    if (!acc.find((el) => el.name === result.projectName)) {
      acc.push({ id: acc.length, name: result.projectName, entries: getEntriesByProjectName(result.projectName, searchResults) })
    }
    return acc
  }, [])
}

export default prepareProjectCollection
