import SearchResult from '../../../../../../../../lib/functional_routes/search'
import { StyledCodeBlock, StyledResult, CustomText, Header, Extend, ExtendWrapper } from './Result.Styled'

import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { useRef, useState } from 'react'
import { Sticky } from 'semantic-ui-react'

type Props = {
  result: SearchResult
}

const Result = ({ result }: Props) => {
  const [extend, setExtend] = useState<boolean>(false)
  const ref: any = useRef()

  return (
    <StyledResult>
      <Sticky active={extend} offset={100} context={ref}>
        <Header onClick={() => setExtend(!extend)}>
          <CustomText>{result.path}</CustomText>
        </Header>
      </Sticky>
      <ExtendWrapper ref={ref}>
        {extend && (
          <Extend>
            <StyledCodeBlock language="javascript" style={atomDark} showLineNumbers>
              {result.content}
            </StyledCodeBlock>
          </Extend>
        )}
      </ExtendWrapper>
    </StyledResult>
  )
}

export default Result
