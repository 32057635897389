import { Project } from '../prepareProjectCollection'
import { StyledLeftMenu, Item } from './LeftMenu.Styled'

type Props = {
  projects: Project[]
}

const LeftMenu = ({ projects }: Props) => {
  return (
    <StyledLeftMenu>
      {projects.map((p) => (
        <Item key={p.id} href={`#project-${p.id}`}>
          {p.name}
        </Item>
      ))}
    </StyledLeftMenu>
  )
}

export default LeftMenu
