//
// ONLY EDIT THIS FILE IN /frontend/src/
//

export const translation = {
  logoText: 'PANDORA',

  dashboard: {
    nav: {
      dashboard: 'Dashboard',
    },
    logoutCTA: 'Ausloggen',
    loginCTA: 'Einloggen',
  },

  projectOverview: {
    projectBubbles: {
      headline: 'PROJECTS',
    },
  },

  search: {
    input: {
      placeholder: 'Up to 50 character are recognized.',
    },
  },

  copy: {
    cta: 'Kopieren',
    success: 'Kopiert',
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Datenschutz',
  },

  generic: {
    salutation: 'Anrede',
    firstname: 'Vorname',
    lastname: 'Nachname',
    street: 'Straße',
    postcode: 'Postleitzahl',
    city: 'Stadt',
    phone: 'Telefon',
    fax: 'Fax',
    mail: 'E-Mail',
    password: 'Passwort',
    save: 'Speichern',
  },

  login: {
    cta: 'Einloggen',
    error: 'E-Mail Adresse oder Passwort falsch.',
  },

  UI: {
    dropdown: {
      emptySelection: 'Bitte wählen',
    },
  },
}

export const langKey = 'DE'
