import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import styled from 'styled-components'

export const StyledCodeBlock = styled(SyntaxHighlighter)``

export const StyledResult = styled.div``

export const CustomText = styled.div<{ headline?: boolean }>`
  color: ${({ theme }) => theme.color.font.primary};
`

export const Header = styled.div`
  height: 50px;
  cursor: pointer;
  position: sticky;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.background.primary};
`

export const Extend = styled.div``

export const ExtendWrapper = styled.div``
