import { Container, Form } from '@think-internet/ui-components'
import styled from 'styled-components'

export const StyledSearch = styled.div`
  width: 100%;
`

export const SearchWrapper = styled.div`
  position: fixed;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 10000;
  height: ${({ theme }) => theme.headerHeight};
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    background-color: ${({ theme }) => theme.color.background.secondary};
    top: 50px;
  }
`

export const SearchContainer = styled(Container)`
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
  }
`

export const SearchForm = styled(Form)`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
`

export const ResultWrapper = styled.div`
  height: calc(100vh - ${({ theme }) => theme.headerHeight} - 50px);
  overflow-y: scroll;
`
