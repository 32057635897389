import { useState } from 'react'
import { useFunctional } from '@think-internet/zeus-frontend-package'
import routes from '../../../redux/routes'

const RunIndex = () => {
  const call = useFunctional(routes.start_indexing_interval)
  const [result, setResult] = useState<boolean>()

  const onClick = async () => {
    const result = await call()

    setResult(result.status)
  }

  return (
    <div>
      <button onClick={onClick}>start indexing interval</button>
      {result !== undefined && <div>{result ? 'success' : 'failed'}</div>}
    </div>
  )
}

export default RunIndex
