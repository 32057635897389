import { StyledResults } from './Results.Styled'
import Project from './Project/Project'
import prepareProjectCollection, { Project as ProjectType } from './prepareProjectCollection'
import 'semantic-ui-css/semantic.min.css'
import LeftMenu from './LeftMenu/LeftMenu'
import SearchResult from '../../../../../../lib/functional_routes/search'
import React from 'react'

export interface IResults {
  searchResults: Array<SearchResult>
}

const Results: React.FC<IResults> = ({ searchResults }) => {
  const projects: Array<ProjectType> = prepareProjectCollection(searchResults)

  const sort = () => projects.sort((a, b) => a.name.localeCompare(b.name))

  if (!Array.isArray(searchResults)) return <></>
  return (
    <StyledResults>
      <LeftMenu projects={projects} />
      {sort().map((project) => (
        <Project key={project.id} project={project} className="project-item" />
      ))}
    </StyledResults>
  )
}

export default Results
